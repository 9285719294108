/* eslint-disable no-console */
import platform from 'platform';
import md5 from 'blueimp-md5';
import { UnsafeSite } from './site';
import descriptions from './info.json';

// Import gifs for different languages
// see: https://stackoverflow.com/questions/69518530/parcel-js-javascript-problem-when-adding-attributes-to-img-element
import enGif from './images/howDoesItWork_en.gif';
import nlGif from './images/howDoesItWork_nl.gif';
import frGif from './images/howDoesItWork_fr.gif';

import pcExample from './images/example_pc.svg';
import spExample from './images/example_sp.svg';

const hashes = new Map([
  ['file', '44d88612fea8a8f36de82e1278abb02f'],
  ['tar', '015cf0f673dd1caa19f8b0d5428ea2f3'],
  ['rar', '909e72b0be53d1b37e298922705ce8d4'],
  ['zip', 'dcb9de304f9c48fdd9586a2375f8d8ef'],
  ['zipzip', '84ceb7dcfc1c46c37d102363b2975c4b'],
]);

// Global object that is updated by each test
let testOutcome = { successWeight: 0, totalWeight: 0, testResult: [] };
let testsRendered = 0;
const totalTests = 6;
let done = false;

/**
 * Set the test example image according to the width of the screen
 */
function setTestExampleImage() {
  const examplePic = document.getElementById('testExampleImage');
  if (window.innerWidth < 800) {
    examplePic.src = spExample;
  } else {
    examplePic.src = pcExample;
  }
}

/**
 * Check whether a string contains a valid number
 * @param {String} num the string representation of the number
 * @returns True iff the string contains a valid number
 */
function isNumeric(num) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(num);
}

/**
 * Render the amount of times the tes has been executed
 */
function renderTestAmount() {
  fetch('server/testAmount', { method: 'GET' })
    .then((response) => {
      response.text().then((textfile) => {
        if (isNumeric(textfile)) {
          document.getElementById('testAmount').textContent = textfile;
        } else {
          document.getElementById('testAmount').textContent = '1234';
        }
      });
    })
    .catch((error) => {
      console.log('Error while fetching amount of tests: ', error);
    });
}

/**
 * Slide the text balloon according to the outcome of the tests
 */
function renderAggrResult() {
  let percentage = 0;
  if (testOutcome.totalWeight !== 0) {
    percentage = (testOutcome.successWeight / (testOutcome.totalWeight));
  }

  const perField = document.getElementById('percentage');
  perField.classList.remove('hidden');

  perField.style = '';

  perField.textContent = `${(percentage * 100).toFixed()}%`;

  const contWidth = document.getElementById('percentageBox').offsetWidth;
  const perWidth = perField.offsetWidth;

  const transl = ((percentage * contWidth) - (perWidth / 2)).toFixed();

  perField.style.transform = `translate(${transl}px, 0px)`;
}

// If the page is not full, the footer should remain at the bottom of the page and not float
window.addEventListener('DOMContentLoaded', () => {
  const adjustFooterPosition = () => {
    const { body } = document;
    const html = document.documentElement;
    const windowHeight = window.innerHeight;
    const bodyHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const footer = document.getElementById('footer');

    if (windowHeight >= bodyHeight) {
      footer.classList.add('fixed', 'bottom-0', 'w-full');
    } else {
      footer.classList.remove('fixed', 'bottom-0', 'w-full');
    }
  };

  setTestExampleImage();
  adjustFooterPosition();
  renderTestAmount();
  // Adjust the footer on the following events
  window.addEventListener('resize', adjustFooterPosition);
  window.addEventListener('resize', setTestExampleImage);
  window.addEventListener('renderResult', adjustFooterPosition);
  window.addEventListener('resize', renderAggrResult);

  // Needed because the lang script can still be loading
  window.dispatchEvent(new Event('newLang'));
});

window.addEventListener('renderResult', () => {
  testsRendered += 1;

  const circleOffset = 100 - (testsRendered / totalTests) * 100;

  document.getElementById('progressCircle').setAttribute('stroke-dashoffset', circleOffset.toFixed());
  document.getElementById('progressText').textContent = `${testsRendered}/${totalTests}`;
});

// Set page specific language contents
window.addEventListener('newLang', () => {
  document.getElementById('emailInput').setAttribute('placeholder', descriptions[window.lang].emailPlaceholder);

  const gif = document.getElementById('howDoesItWorkGif');
  switch (window.lang) {
    case 'nl':
      gif.src = nlGif;
      break;
    case 'en':
      gif.src = enGif;
      break;
    case 'fr':
      gif.src = frGif;
      break;
    default:
      console.log('Language error, loading standard gif');
      gif.src = enGif;
      break;
  }
});

document.getElementById('emailWarningClose').addEventListener('click', () => {
  document.getElementById('emailWarning').classList.add('hidden');
});

document.getElementById('newsletterText').addEventListener('click', () => {
  const newsletterInput = document.getElementById('newsletterInput');
  if (newsletterInput.checked) {
    newsletterInput.checked = false;
  } else {
    newsletterInput.checked = true;
  }
});

document.getElementById('disclaimerText').addEventListener('click', () => {
  const disclaimerInput = document.getElementById('agreeInput');
  if (disclaimerInput.checked) {
    disclaimerInput.checked = false;
  } else {
    disclaimerInput.checked = true;
  }
});

/**
 * Close an answer section residing in the FAQ on the home page
 * @param {Number} val The answer section that needs to be closed
 */
function closeAnswSection(val) {
  document.getElementById(`question${val}`).classList.remove('rounded-t-lg');
  document.getElementById(`question${val}`).classList.add('rounded-lg');

  const p = document.getElementById(`para${val}`);
  const plus = document.getElementById(`plus${val}`);
  const minus = document.getElementById(`minus${val}`);

  plus.classList.remove('hidden');
  plus.classList.add('block');

  minus.classList.add('hidden');
  minus.classList.remove('block');

  p.classList.add('hidden');
  p.classList.remove('block');

  p.classList.add('exiting');
  p.classList.remove('expanded');
}

/**
 * Open the section of the question with the given number
 * This closes all other sections
 * @param {Number} val section number
 */
window.openSection = function openSection(val) {
  // Close all other sections
  const potOpen = [1, 2, 3, 4];
  const index = potOpen.indexOf(val);
  potOpen.splice(index, 1);

  potOpen.forEach((section) => closeAnswSection(section));

  // Open the section
  const p = document.getElementById(`para${val}`);
  const plus = document.getElementById(`plus${val}`);
  const minus = document.getElementById(`minus${val}`);

  [p, plus, minus].forEach((DOMElement) => {
    DOMElement.classList.toggle('hidden');
    DOMElement.classList.toggle('block');
  });

  const expanded = p.classList.contains('hidden');

  if (expanded) {
    p.classList.remove('expanded');
    p.classList.add('exiting');
  } else {
    p.classList.add('expanded');
    p.classList.remove('exiting');
  }

  document.getElementById(`question${val}`).classList.toggle('rounded-t-lg');
  document.getElementById(`question${val}`).classList.toggle('rounded-lg');
};

/**
 * Checks whether an email address corresponds with a common non-company domain
 * @param {String} email The email to be checked
 * @returns True iff the email contains a common domain name
 */
function isCommonDomain(email) {
  const commomDomains = ['telenet', 'skynet', 'hotmail', 'gmail', 'protonmail', 'live', 'yopmail'];
  let contains = false;

  commomDomains.forEach((domain) => {
    if (email.includes(domain)) {
      contains = true;
    }
  });
  return contains;
}

// Event listener for submit email button
document.getElementById('emailButton').addEventListener('click', () => {
  if (done === false) {
    return;
  }

  const emailInput = document.getElementById('emailInput');

  const emailReg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (!document.getElementById('agreeInput').checked) {
    document.getElementById('checkDisclBox').classList.add('border-2', 'border-red-500', 'rounded-lg', 'p-2', 'w-fit');
    document.getElementById('warningPing').classList.remove('hidden');
    return;
  }

  document.getElementById('checkDisclBox').classList.remove('border-2', 'border-red-500', 'rounded-lg', 'p-2', 'w-fit');
  document.getElementById('warningPing').classList.add('hidden');

  if ((!emailInput.value.match(emailReg)) || (isCommonDomain(emailInput.value))) {
    document.getElementById('emailWarning').classList.remove('hidden');
    return;
  }

  testOutcome.newsletter = document.getElementById('newsletterInput').checked;

  document.getElementById('emailWarning').classList.add('hidden');

  // Send result to server
  testOutcome.address = emailInput.value;
  testOutcome.language = window.lang;

  fetch('server/mailPdf', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(testOutcome),
  }).then(() => {
    // Open new thank you page
    window.open(`/emailsuccess.html?lang=${window.lang}`, '_self');
  }).catch((err) => {
    console.log('Error while submitting email request:', err);
  });
});

// Event listener for enter when filling in Email form
document.getElementById('emailInput').addEventListener('keypress', (event) => {
  if (event.key === 'Enter') {
    document.getElementById('emailButton').click();
  }
});

/**
 * Reset the previous test results
 */
function resetTest() {
  testOutcome = { successWeight: 0, totalWeight: 0, testResult: [] };
  testsRendered = 0;
  done = false;
}

/**
 * Hide the start page and render the result components
 */
function hideStartPage() {
  document.getElementById('resultBox').classList.remove('hidden');
  document.getElementById('emailBox').classList.remove('hidden');
  document.getElementById('userInfo').classList.remove('hidden');

  document.querySelectorAll("[id$='advice']").forEach((element) => {
    element.classList.add('hidden');
  });
  document.getElementById('introduction').classList.add('hidden');
  document.getElementById('whoarewe').classList.add('hidden');
  document.getElementById('startSection').classList.add('hidden');
  document.getElementById('cta2').classList.add('hidden');

  document.getElementById('questionDiv1').classList.add('hidden');
  document.getElementById('questionDiv2').classList.add('hidden');
  document.getElementById('questionDiv4').classList.add('hidden');
}

/**
 * Signal to the user that the tests are loading
 */
function toggleLoadingEffect() {
  document.getElementById('resultBox').classList.toggle('animate-pulse');
  document.getElementById('aggrBar').classList.toggle('animate-pulse');
  document.getElementById('progressBox').classList.toggle('animate-pulse');
}

/**
 * Render the OS and Browser info on the page
 */
function renderBrowserInfo() {
  document.getElementById('systemInfo').textContent = `${platform.os.toString()}: ${platform.name}`;
}

/**
 * Check wether a string represents an IPv4 or 6 address
 * @param {string} ip String representation of an IP address
 * @returns true iff the string represents a valid IPv(4|6)
 */
function checkIpAddress(ip) {
  const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
}

/**
 * Render the IP information of the user by sending a query to the Go server
 */
function renderIPInfo() {
  fetch('server/myip', { method: 'GET' })
    .then((response) => {
      response.text().then((textfile) => {
        if (checkIpAddress(textfile)) {
          document.getElementById('ipInfo').textContent = textfile;
        } else {
          document.getElementById('ipInfo').textContent = '?';
        }
      }).catch((error) => console.log(`Error while parsing myip response: ${error}`));
    })
    .catch((error) => console.log(`Error while contacting Go server for IP info: ${error}`));
}

/**
 * Render the location of the user when a test is started
 */
async function renderLocation() {
  const countryResponse = await fetch('server/myip/country', { method: 'GET' });
  const countryRecord = await countryResponse.json();

  if (countryRecord == null) {
    console.log('Error: empty maxmind response');
    return;
  }

  const country = countryRecord.country.names.en;

  const box = document.getElementById('locInfo');
  if (country == null) {
    box.textContent = '?';
  } else {
    box.textContent = country;
  }
}

/**
 * Signal to the server that a new test was started
 */
async function logTestStart() {
  fetch('server/startTest', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((error) => {
    console.log('Error while logging test start: ', error);
  });
}

/**
 * Check whether the sites of a certain category are blocked or not
 * @param {UnsafeSite[]} sites List of unsafe sites
 * @precondition Sites is not empty
 * @returns The score and name that can be used to index the info.json
 */
async function checkSiteCategory(sites) {
  const promises = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const site of sites) {
    promises.push(site.test());
  }

  // Have to wait for all the fetches for a certain category to be completed before aggregation
  await Promise.allSettled(promises);

  // All sites testen, time to aggregate
  const aggrCat = UnsafeSite.aggregateCategory(sites);
  let percentage = (aggrCat.blocked / (aggrCat.blocked + aggrCat.notBlocked));

  // Adjust for potential false positive from secureDNS
  if (aggrCat.notBlocked === 1) {
    percentage = 1;
  }

  testOutcome.successWeight += percentage * 10;
  testOutcome.totalWeight += 10;

  const infoKey = `secureDNS.${sites[0].category}.`;
  testOutcome.testResult.push({ name: infoKey, score: percentage });
  return { score: percentage, name: infoKey };
}

/**
 * Render the result of a test
 * @param {Boolean} success Dit the test pass the bar?
 * @param {String} key entry for test information in info JSON file
 * @param {Number} score Number ranging from 0 to 100 representing the test score
 */
function renderResult(success, key, score) {
  const { lang } = window;
  let icon;
  let scoreResult;

  if (success) {
    const iconTempl = document.getElementById('goodIcon');
    icon = iconTempl.content.firstElementChild.cloneNode(true);
    const scoreTempl = document.getElementById('goodScore');
    scoreResult = scoreTempl.content.firstElementChild.cloneNode(true);
  } else {
    const iconTempl = document.getElementById('badIcon');
    icon = iconTempl.content.firstElementChild.cloneNode(true);
    const scoreTempl = document.getElementById('badScore');
    scoreResult = scoreTempl.content.firstElementChild.cloneNode(true);
  }

  // Set the icon
  const testIcon = document.getElementById(`${key}icon`);
  testIcon.innerHTML = ''; // Clear the existing content
  testIcon.appendChild(icon);
  testIcon.classList.remove('animate-pulse', 'blur-sm');

  // Set the title
  const testTitle = document.getElementById(`${key}title`);
  testTitle.textContent = descriptions[lang][`${key}categoryName`];
  testTitle.setAttribute('data-text', `${key}categoryName`);

  // Set the score
  const testScore = document.getElementById(`${key}score`);
  scoreResult.textContent = `${score}%`;
  testScore.innerHTML = '';
  testScore.append(scoreResult);
  testScore.classList.remove('animate-pulse', 'blur-sm', 'text-AXSOrange');

  // Fire new event so that progress meter can react
  window.dispatchEvent(new Event('renderResult'));
}

/**
 * Render the results for secureDNS one by one
 * @param {Object} results Object with a score and name that
 *                         can be used to index in the info.json file
 */
async function renderDNSResults(results) {
  const sleep = (delay) => new Promise((resolve) => { setTimeout(resolve, delay); });
  // eslint-disable-next-line no-restricted-syntax
  for (const res of results) {
    // Could be the case that a value is not defined
    // (e.g., when no sites for a category are found )
    if (typeof res.value !== 'undefined' && res.value.name !== 'secureDNS.apt.') {
      // Aggregate malware and APT results
      if (res.value.name === 'secureDNS.malware.') {
        const aptScore = results.filter((elem) => elem.value.name === 'secureDNS.apt.')[0].value.score;
        const aggrScore = (aptScore + res.value.score) / 2;
        renderResult(aggrScore > 0.75, res.value.name, (aggrScore * 100).toFixed());
      } else {
        renderResult(res.value.score > 0.75, res.value.name, (res.value.score * 100).toFixed());
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(2000);
    }
  }
}

/**
 * Test if a specific category of unsafesite is blocked
 * @param {String} category The category to be checked
 * @returns The score and name that can be used to index the info.json
 */
async function testDNSCategory(category) {
  const DNSResponse = await fetch(`server/sites/${category}`, { method: 'GET' });
  const DNSResult = await DNSResponse.json();

  const sites = [];
  DNSResult.forEach((result) => {
    sites.push(new UnsafeSite(result.domain, result.category));
  });
  if (sites.length > 0) {
    const result = await checkSiteCategory(sites);
    return result;
  }
  return null;
}

/**
 * Test whether certain catagories of sites are blocked or not
 * Each category is tested asynchronously
 * Rendering is done synchronously
 */
async function testSecureDNS() {
  const categories = ['phishing', 'malware', 'scam', 'spam', 'apt', 'botnet'].sort();
  const promises = [];
  categories.forEach((category) => {
    promises.push(testDNSCategory(category));
  });

  const res = await Promise.allSettled(promises);
  await renderDNSResults(res);
}

/**
 * Test whether it is possile to receive a certain form of eicar file
 * @param {string} form the tested form
 */
async function testEicarForm(form) {
  const infoKey = `eicar.${form}.`;
  try {
    const eicarResponse = await fetch(`server/eicar/${form}`, { method: 'GET' });
    const eicarFile = await eicarResponse.text();

    const expectedhash = hashes.get(form);
    const hash = md5(eicarFile, null);

    if (hash !== expectedhash) {
      testOutcome.successWeight += 10;
    }

    testOutcome.totalWeight += 10;

    if (hash !== expectedhash) {
      testOutcome.testResult.push({ name: infoKey, score: 1 });
      return 1;
    }
    testOutcome.testResult.push({ name: infoKey, score: 0 });
    return 0;
  } catch (error) {
    // It can be the case that the proxy returns a page with no CORS headers
    // This means the eicar file is blocked
    testOutcome.successWeight += 10;
    testOutcome.totalWeight += 10;
    testOutcome.testResult.push({ name: `eicar.${form}.`, score: 1 });
    return 1;
  }
}

/**
 * Test all eicar files aync
 * When all tests are resolved, render the result
 */
async function testEicar() {
  // Eicar is a fast test, better for UX to wait a second
  const sleep = (delay) => new Promise((resolve) => { setTimeout(resolve, delay); });
  await sleep(1000);

  const promises = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const form of hashes.keys()) {
    promises.push(testEicarForm(form));
  }

  const res = await Promise.allSettled(promises);

  const sum = res.reduce((acc, result) => result.value + acc, 0);
  const percentage = sum / res.length;

  renderResult(percentage > 0.6, 'eicar.file.', (percentage * 100).toFixed());
}

// Event listener for startButton
document.getElementById('startButton').addEventListener('click', () => {
  // Start a new test
  resetTest();
  hideStartPage();
  toggleLoadingEffect();

  // Display user Info
  renderBrowserInfo();
  renderIPInfo();
  renderLocation();

  const promises = [];
  promises.push(logTestStart());
  promises.push(testSecureDNS());
  promises.push(testEicar());

  Promise.allSettled(promises).then(() => {
    document.getElementById('aggrBar').classList.remove('hidden');
    toggleLoadingEffect();

    // Render the advice at the end
    document.getElementById('advice').classList.remove('hidden');
    const percentage = testOutcome.successWeight / testOutcome.totalWeight;

    if (percentage > 0.8) {
      document.getElementById('greenadvice').classList.remove('hidden');
    } else if (percentage > 0.55) {
      document.getElementById('orangeadvice').classList.remove('hidden');
    } else {
      document.getElementById('redadvice').classList.remove('hidden');
    }

    // Render the aggregation result and scroll towards it
    renderAggrResult();
    done = true;
    window.scrollTo({
      top: document.getElementById('aggrBar').offsetTop,
      behavior: 'smooth', // You can use 'auto' for instant scrolling
    });
  })
    .catch((error) => {
      console.log('Error while conducting tests: ', error);
    });
});

document.getElementById('cta2Button').addEventListener('click', () => {
  window.scrollTo({
    top: document.getElementById('homeHeader').offsetTop,
    behavior: 'smooth',
  });

  document.getElementById('startButton').click();
});
