/* eslint-disable no-console */
// eslint-disable-next-line import/prefer-default-export
export class UnsafeSite {
  #url;

  #category;

  #isBlocked;

  /**
   * Create Unsafe website
   * @param {string} url The domain of the unsafe site
   * @param {string} category The secureDNS category
   */
  constructor(url, category) {
    this.#category = category;
    this.#url = url;
  }

  get url() {
    return this.#url;
  }

  get isBlocked() {
    return this.#isBlocked;
  }

  get category() {
    return this.#category;
  }

  /**
   * Fetch a site over https with a timeout of 8 sec
   * @param {string} domain The site's domain
   * @returns The site's response
   */
  async #fetchWithTimeout() {
    const timeout = 8000;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(`https://${this.#url}`, {
      methos: 'GET',
      mode: 'no-cors',
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  }

  /**
   * Test of a site is blocked or not
   * A site is blocked iff a timeout occurs
   * @modifies {isBlocked} Set to True if the site is blocked
   */
  async test() {
    await this.#fetchWithTimeout()
      .then(() => {
        console.log(`${this.url} is unsafe (cat: ${this.#category}) and not blocked`);
        this.#isBlocked = false;
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.log(`${this.url} is blocked for a different reason`);
        }
        this.#isBlocked = true;
      });
  }

  /**
   * Aggregate a list of sites to see how many are blocked and not blocked
   * @param {UnsafeSite[]} sites The sites to be aggregated
   * @returns {Object} result The aggregation of all the sites
   * @returns {number} result.blocked The amount of blocked sites
   * @returns {number} result.notBlocked The amount of sites which are not blocked
   */
  static aggregateCategory(sites) {
    const result = { blocked: 0, notBlocked: 0 };

    // eslint-disable-next-line no-restricted-syntax
    for (const site of sites) {
      if (site.isBlocked) {
        result.blocked += 1;
      } else {
        result.notBlocked += 1;
      }
    }

    return result;
  }
}
